<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="dataTable"
      :search="search"
      :loading="loadingTable"
      :disable-sort="$vuetify.breakpoint.name == 'xs'"
      :key="`list-workeffort-${tenantId}`"
      :items-per-page="15"
      :options.sync="options"
      loading-text="Cargando tipos de eventos..."
      no-results-text="No se han encontrado tipos de eventos"
      no-data-text="Sin tipos de eventos"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <v-row justify="center" class="px-4 mt-4">
          <v-col cols="3">
            <v-toolbar-title>
              <v-icon>mdi-calendar-question</v-icon> TIPOS DE EVENTOS
            </v-toolbar-title>
          </v-col>
          <v-col md="6">
            <v-text-field v-model="search" label="Buscar" />
          </v-col>
          <v-col md="3" class="mt-4" v-if="storeAction">
            <v-btn small color="primary" @click="openDialogWorkEfforType">
              <v-icon>mdi-plus</v-icon> Nuevo tipo de evento
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.estados="{ item }" v-if="editAction">
        <v-btn icon @click="abrirDialogoEstados(item.work_effort_type_id)"
          ><v-icon>mdi-dots-horizontal</v-icon>{{ item.estados }}</v-btn
        >
      </template>
      <template v-slot:item.actions="{ item }" v-if="editAction">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="editWorkEffortType(item)"
            >
              <v-icon color="orange">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="borrarWorkEffortType(item)">
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- DIALOGO TIPO DE EVENTO-->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-overlay :value="overlay" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-toolbar flat color="green" dark height="40">
          <v-toolbar-title>{{ action }} tipo de evento</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="mx-1" @click="dialog = false" small icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-form ref="form_assign_work_effort">
          <v-col cols="12">
            <v-row>
              <v-col cols="6" class="mt-2">
                <v-text-field
                  v-model="editedItem.work_effort_type_id"
                  label="Identificador del tipo de evento"
                  dense
                  class="text-center pb-2"
                  :rules="required"
                  counter="20"
                  v-mask="'XXXXXXXXXXXXXXXXXXXX'"
                  @keyup="
                    editedItem.work_effort_type_id = editedItem.work_effort_type_id.toUpperCase()
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-autocomplete
                  v-model="editedItem.parent_type_id"
                  label="Evento padre"
                  :items="parentWorkEffortType"
                  dense
                  item-value="work_effort_type_id"
                  item-text="description"
                  clearable
                />
              </v-col>
            </v-row>
            <v-row class="mb-4">
              <v-col md="12">
                <v-text-field
                  v-model="editedItem.description"
                  type="text"
                  label="Nombre del tipo de evento"
                  dense
                  class="text-center pb-2"
                  :rules="required"
                />
              </v-col>
            </v-row>
            <em
              >Nota: si no se coloca un tipo de evento padre este se convertirá
              en un tipo de evento padre</em
            >
          </v-col>
          <v-col>
            <v-row>
              <v-col class="text-center">
                <v-btn
                  small
                  color="primary"
                  @click="storeWorkEffortType"
                  class="mx-2"
                >
                  <v-icon>mdi-content-save</v-icon> GUARDAR
                </v-btn>
                <v-btn small color="default" @click="close" class="mx-2">
                  <v-icon>mdi-cancel</v-icon> Cerrar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- DIALOGO ESTADOS DE TIPO DE EVENTO-->
    <v-dialog v-model="dialogoEstados" persistent max-width="600px" scrollable>
      <v-card class="ma-0 pa-0">
        <v-toolbar dark flat height="45" color="green" fixed>
          <v-toolbar-title>Estados del tipo de evento</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="mx-1"
            title="Cerrar"
            @click="dialogoEstados = false"
            small
            dark
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-row>
          <v-col cols="6" class="ma-2">
            <v-select
              v-model="datosEventoEstado.status_id"
              label="Estado"
              :items="listaEstados"
              item-text="description"
              item-value="status_id"
            ></v-select>
          </v-col>
          <v-col cols="4" class="mt-4">
            <v-btn
              @click="agregarEstado()"
              small
              color="primary"
              title="Agregar estado"
              ><v-icon small>mdi-plus</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :items="estadosTe"
              :headers="headerEstadosTe"
              hide-default-footer
            >
              <template v-slot:item.opciones="{ item }">
                <v-btn @click="borrarEstadoEvento(item)" small icon color="red"
                  ><v-icon small>mdi-delete</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import Vue from "vue";

export default {
  name: "TipoEventoComponent",
  data: () => ({
    show: false,
    user: "",
    password: "",
    company: "",
    requiredRule: [(v) => !!v || "El campo es requerido"],
    options: {},
    dataTable: [],
    setfilters: [],
    parentWorkEffortType: [],
    search: null,
    dialog: false,
    overlay: false,
    action: "Nuevo",
    editedItem: {
      work_effort_type_id: null,
      parent_type_id: null,
      description: null,
    },
    defaultEditedItem: {
      work_effort_type_id: null,
      parent_type_id: null,
      description: null,
    },
    required: [(v) => !!v || "El campo es requerido"],
    headers: [
      { text: "ID", value: "work_effort_type_id" },
      { text: "Nombre", value: "description" },
      { text: "Pertenece a", value: "parent_work_effort_type" },
      { text: "Estados", value: "estados" },
      { text: "Acciones", value: "actions" },
    ],

    headerEstadosTe: [
      { text: "Codigo", value: "status_id" },
      { text: "Estados", value: "description" },
      { text: "Acciones", value: "opciones" },
    ],
    estadosTe: [],
    dialogoEstados: false,
    datosEventoEstado: {},
    listaEstados: [],
  }),
  watch: {
    options: {
      handler() {
        this.getTIposEventos();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("master", ["loadingBtn"]),

    ...mapState("master", ["loadingTable", "tenantId"]),

    ...mapState("access", ["idActionsRol"]),

    storeAction() {
      return this.idActionsRol.filter((e) => e == "GUARDA_TIPO_EVENTO").length;
    },

    editAction() {
      return this.idActionsRol.filter((e) => e == "EDITA_TIPO_EVENTO").length;
    },
  },
  methods: {
    ...mapMutations("master", ["setTenantId"]),

    ...mapActions("access", ["login"]),

    ...mapMutations("master", ["setUrl", "setLoadingTable"]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarDatos() {
      this.setLoadingTable(true);

      this.dataTable = [];

      this.setUrl("tipo_evento");
      this.requestApi({
        method: "GET",
        data: {
          tipo_lista: "estadosEvento",
        },
      })
        .then((res) => {
          this.listaEstados = res.data._embedded.tipo_evento;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    getTIposEventos() {
      this.setLoadingTable(true);

      this.dataTable = [];

      this.setUrl("tipo_evento");
      this.requestApi({
        method: "GET",
        data: {
          tipo_lista: "tiposEvento",
        },
      })
        .then((res) => {
          this.dataTable = res.data._embedded.tipo_evento;
          this.parentWorkEffortType = res.data._embedded.tipo_evento;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    openDialogWorkEfforType() {
      this.dialog = true;
      Object.assign(this.editedItem, this.defaultEditedItem);
    },

    storeWorkEffortType() {
      if (!this.$refs.form_assign_work_effort.validate()) return false;

      this.setUrl("tipo_evento");
      this.overlay = true;

      this.requestApi({
        method: "POST",
        data: {
          tenantId: this.tenantId,
          ...this.editedItem,
        },
      })
        .then((res) => {
          console.log(res);
          this.close();
          this.getTIposEventos();
          this.overlay = false;
          this.alertNotification({
            param: {
              html: res.data.msg,
            },
          });
        })
        .then(() => {
          this.overlay = false;
        });
    },

    editWorkEffortType(wet) {
      this.action = "Editar";
      Object.assign(this.editedItem, wet);
      this.dialog = true;
    },
    borrarWorkEffortType(wet) {
      Vue.swal({
        html: "Está seguro de eliminar este estado ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.setLoadingTable(true);
          this.setUrl("tipo_evento");
          this.requestApi({
            method: "DELETE",
            data: {
              accion: "borrarTipoEvento",
              work_effort_type_id: wet.work_effort_type_id,
            },
          })
            .then((res) => {
              //console.log(res.data);
              this.getTIposEventos();
              this.setLoadingTable(false);
            })
            .then(() => {
              this.setLoadingTable(false);
            })
            .finally(() => {
              this.setLoadingTable(false);
            });
        }
      });
    },
    abrirDialogoEstados(wetId) {
      this.datosEventoEstado = { work_effort_type_id: wetId, status_id: "" };
      this.dialogoEstados = true;
      this.cargarEstados(wetId);
    },

    cargarEstados(work_effort_type_id) {
      this.setLoadingTable(true);

      this.setUrl("estado-evento");
      this.requestApi({
        method: "GET",
        data: {
          tipo_lista: "listaEstadosTipoEvento",
          work_effort_type_id: work_effort_type_id,
        },
      })
        .then((res) => {
          this.estadosTe = res.data.detail;
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    agregarEstado() {
      this.setLoadingTable(true);

      this.setUrl("estado-evento");
      this.requestApi({
        method: "POST",
        data: {
          accion: "agregarEstadoTipoEvento",
          work_effort_type_id: this.datosEventoEstado.work_effort_type_id,
          status_id: this.datosEventoEstado.status_id,
        },
      })
        .then((res) => {
          this.cargarEstados(this.datosEventoEstado.work_effort_type_id);
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    close() {
      this.dialog = false;
      Object.assign(this.editedItem, this.defaultEditedItem);
      this.action = "Nuevo";
    },
  },
  mounted() {
    this.cargarDatos();
  },
};
</script>

<style></style>
